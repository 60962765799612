/* Reset global styles */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* 禁用全局滚动 */
  box-sizing: border-box;
}

* {
  box-sizing: inherit; /* 继承 box-sizing 设置 */
}

body, button, input, textarea {
  font-family: 'Source Code Pro', monospace;
  font-size: 15px;
  margin: 0;
  padding: 0;
}

/* Main container styles */
.chat-container {
  display: flex;
  flex-direction: column; /* 垂直排列 */
  height: 100vh; /* 占满整个屏幕高度 */
  width: 100%; /* 占满屏幕宽度 */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.messages-list {
  flex-grow: 1; /* 占据剩余空间 */
  overflow-y: auto; /* 启用垂直滚动 */
  padding: 10px;
  margin: 0;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch; /* 启用滚动的弹性效果 */
}

.disclaimer {
  max-height: 100px; /* Set a maximum height */
  margin: 0 10px -8px 10px;
  padding: 0;
  font-family: 'Source Code Pro', monospace;
  font-size: 13px;
  text-align: center;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch; /* Enable elastic scrolling */
  border-top: 1px solid black; /* 恢复横线 */
}

.message {
  padding: 10px 0;
  border-radius: 0; /* 无圆角 */
  word-wrap: break-word;
  color: black;
  align-self: flex-end;
  border-bottom: 1px solid black; /* 恢复横线 */
}

.message:last-child {
  border-bottom: none; /* Remove border from the last message */
}

.input-area {
  display: flex;
  padding: 10px;
  width: 100%; /* 占满父容器宽度 */
  margin: 0;
  box-sizing: border-box;
  padding-bottom: 40px;
}
.input-area textarea {
  flex-grow: 1;
  border: 1px solid black;
  padding: 10px;
  border-radius: 0; /* 无圆角 */
  margin-right: 10px;
  resize: none; /* 禁用调整大小 */
}

.input-area textarea:focus {
  border: 1.5px solid black;
  outline: none; /* 去掉默认的 focus 样式 */
}

.input-area button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  user-select: none; /* Prevent text selection */
}

.input-area button:hover {
  background-color: #0056b3;
}

.input-area button:disabled {
  background-color: #A9A9A9; /* Special color for disabled state */
  cursor: not-allowed;
}
